<template>
  <router-link :to="{ name: 'Home' }">
    <img
      src="@/assets/images/logo.png"
      alt="logo"
      class="h-7 w-28 lg:h-8 lg:w-32"
    />
  </router-link>
</template>

<script>
export default {};
</script>

<style scoped></style>
