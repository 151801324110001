<template>
  <div>
    <div id="transact">
      <div id="printSec" class="font-source mt-3">
        <div class="flex justify-center mb-4">
          <img
            src="@/assets/images/logo.png"
            alt="Kares Logo"
            class="w-40 h-10"
          />
        </div>
        <div class="bg-green-50 px-6 py-3 flex justify-between text-xs">
          <h4 class="text-gray-500 font-semibold">Customer ID</h4>
          <h3 class="font-bold text-ansBlack">{{ invoice.customerID }}</h3>
        </div>

        <div class="bg-green-50 px-6 py-3 flex justify-between text-xs mt-2">
          <h4 class="text-gray-500 font-semibold">Transaction status</h4>
          <h3 class="font-bold text-ansGreen uppercase">
            {{ invoice.status }}
          </h3>
        </div>

        <div class="mt-6">
          <div class="mx-auto w-full max-w-[511px]">
            <div class="bg-blue-50 py-6 text-center text-ansBlack rounded">
              <p class="text-xs">Amount received</p>
              <h4 class="text-xl font-black mt-3">
                {{ invoice.amount | toCurrency }}
              </h4>
            </div>

            <div v-if="paid" class="flex justify-between text-xs mt-4">
              <p>Beneficiary Account Name</p>
              <h4 class="text-right font-bold">
                {{ invoice.metaData.BeneficiaryAccountName }}
              </h4>
            </div>

            <div v-if="paid" class="flex justify-between text-xs mt-4">
              <p>Originator Account Name</p>
              <h4 class="text-right font-bold">
                {{ invoice.metaData.OriginatorAccountName }}
              </h4>
            </div>

            <div v-if="paid" class="flex justify-between text-xs mt-4">
              <p>Originator Account Number</p>
              <h4 class="text-right font-bold">
                {{ invoice.metaData.OriginatorAccountNumber }}
              </h4>
            </div>

            <div v-if="paid" class="flex justify-between text-xs mt-4">
              <p>Account Number</p>
              <h4 class="text-right font-bold">
                {{ invoice.metaData.accountNumber }}
              </h4>
            </div>

            <div v-if="paid" class="flex justify-between text-xs mt-3">
              <p>Payment Channel</p>
              <h4 class="text-right font-bold">{{ invoice.paymentChannel }}</h4>
            </div>

            <div v-if="paid" class="flex justify-between text-xs mt-3">
              <p>Payment Reference</p>
              <h4 class="text-right font-bold">
                {{ invoice.paymentReference }}
              </h4>
            </div>

            <div class="flex justify-between text-xs mt-3">
              <p>Date</p>
              <h4 class="text-right font-bold">
                {{ invoice.createdAt | moment("Do MMM YYYY") }}
              </h4>
            </div>

            <div class="flex justify-between text-xs mt-3">
              <p>Time</p>
              <h4 class="text-right font-bold">
                {{ invoice.createdAt | moment("hh:mm:ssa") }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-center px-2 mt-20 py-5 gap-6">
      <button
        @click="printInvoice"
        class="flex justify-center gap-5 px-3 py-2.5 rounded border border-gray-200"
      >
        <img src="@/assets/images/email/print.svg" alt="icons" class="inline" />
        <span>Print</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Payment",

  data() {
    return {
      getting: false,
      notFound: false,
      transaction: null,
    };
  },

  computed: {
    paid() {
      return this.invoice.transactionStatus != "pending";
    },
  },

  props: {
    invoice: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  async created() {
    // await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.getting = true;

        const res = await this.$http.get(
          "/transaction/payments/" + this.invoice.invoiceNumber
        );
        this.getting = false;

        if (!res) {
          return;
        }
        this.notFound = false;

        const { data } = res;
        this.transaction = data;
      } catch (err) {
        console.log(err);
        this.getting = false;
      }
    },

    printInvoice() {
      const divContents = document.getElementById("transact").innerHTML;
      const a = window.open("", "", "");
      a.document.write("<html><head><title>" + document.title + "</title>");
      a.document.write(
        ' <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/2.2.19/tailwind.min.css">'
      );
      a.document.write(`<style>
        #printSec {
          max-width: 600px;
          margin: 1rem auto;
          border: 1px solid gray;
          border-radius: 1rem;
          padding: 1rem;
        }
      </style>`);
      a.document.write("</head><body>");
      a.document.write(divContents);
      a.document.write("</body></html>");
      a.document.close();
      a.focus();
      setTimeout(() => {
        a.print();
        a.close();
      }, 1000);
      return true;
    },
  },
};
</script>
